<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="elemento.descripcion"
          label="Descripción"
          :counter="100"
          :rules="descripcionRules"
          required
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field
          prepend-icon="mdi-numeric"
          v-model="elemento.numero_serie"
          label="Nº Serie"
          append-outer-icon="mdi-close"
          :counter="50"
          :rules="nroserieRules"
          @click:append-outer="onDelete"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-checkbox
          v-model="elemento.controlar"
          label="Controlar al egreso"
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "ElementoInputs",
  data() {
    return {
      tipos: [],
      descripcion: "",
      nroserie: "",
      controlar: false,
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      descripcionRules: [
        (v) => !!v || "Debe ingresar una descripción",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo descripcion de teléfono debe ser menor o igual a 100 caracteres.",
      ],
      nroserieRules: [
        (v) =>
          v.length <= 50 ||
          "La longuitud del campo Número de Serie debe ser menor o igual a 50 caracteres.",
      ],
    };
  },
  props: {
    elemento: {
      type: Object,
      required: true,
    },
    // key: {
    //   type: Number,
    //   required: true,
    // }
  },
  methods: {
    onDelete() {
      this.$emit("elementoForm-deleted", this.elemento);
    },
  },
  created() {
    // VisitasService.getTiposTelefono()
    //   .then((response) => {
    //     this.tipos = response.data;
    //   })
    //   .catch((error) => console.log(error));
  },
};
</script>
