// 00111671111@APELLIDO@NOMBRE@M@28123456@A@10/11/1988@07/06/2015@702
// 00111671111@APELLIDO@NOMBRE@M@28123456@A@10/11/1988@07/06/2015@702
function dniDecode(strDoc, splitchar = "@") {
  if (strDoc.split(splitchar).length > 10)
    return dniDecodeOld(strDoc, splitchar);
  else return dniDecodeNew(strDoc, splitchar);
}

function dniDecodeOld(strDoc, splitchar = "@") {
  var gen = strDoc.split(splitchar)[8];
  if (gen == "M") gen = 1;
  else if (gen == "F") gen = 2;
  else if (gen == "X") gen = 3;
  let datosDni = {
    nombres: strDoc.split(splitchar)[5],
    apellidos: strDoc.split(splitchar)[4],
    documento: strDoc.split(splitchar)[1].trim(),
    documentotipo: 1,
    genero: gen,
    nacimiento: strDoc.split(splitchar)[7],
  };
  return datosDni;
}

function dniDecodeNew(strDoc, splitchar = "@") {
  var gen = strDoc.split(splitchar)[3];
  if (gen == "M") gen = 1;
  else if (gen == "F") gen = 2;
  else if (gen == "X") gen = 3;
  let datosDni = {
    nombres: strDoc.split(splitchar)[2],
    apellidos: strDoc.split(splitchar)[1],
    documento: strDoc.split(splitchar)[4],
    documentotipo: 1,
    genero: gen,
    nacimiento: strDoc.split(splitchar)[6],
  };
  return datosDni;
}

export default dniDecode;
