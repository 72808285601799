<template>
  <div>
    <!-- 
    
    <v-autocomplete v-model="values" :items="items" class="ma-6"
    :search-input.sync="search" outlined dense chips
    small-chips label="Outlined" multiple>
  <template v-slot:no-data>
    <v-btn @click="items.push(search);values.push(search);search=''">
    add: {{ search }}</v-btn>
    </template>
</v-autocomplete>

new Vue({
  el: '#app',
  vuetify: new Vuetify(),
  data: () => ({
    items: ['foo', 'bar', 'fizz', 'buzz'],
    values: [],
    value: null,
    search:'foo'
  }),
})

    
    > -->

    <v-autocomplete
      ref="autocomplete"
      v-model="empresa"
      :items="empresas"
      item-value="id"
      item-text="razonsocial"
      label="Empresa"
      cache-items
      :loading="loading"
      :search-input.sync="searchEmpresa"
      no-data-text="No se encontraron resultados"
      @change="changeEmpresa"
    >
      <template v-slot:no-data>
        <v-btn x-small type="submit" @click="crearEmpresa">
          Crear: {{ searchEmpresa }}</v-btn
        >
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService";

export default {
  name: "EmpresasAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      searchEmpresa: null,
      empresa: null,
      empresas: [],
      loading: false,
      autorizaRules: [
        (v) => !!v || "Debe seleccionar una persona",
        (v) => v.length > 3 || "Debe ingresar mas de 3 caracteres",
      ],
    };
  },
  methods: {
    crearEmpresa() {
      if (this.searchEmpresa.length < 4) return;

      this.loading = true;
      let empresa = {
        razonsocial: this.searchEmpresa.toUpperCase(),
      };

      VisitasService.postEmpresa(empresa)
        .then((response) => {
          this.searchEmpresa = "";
          this.loading = false;
          this.empresas.push(response.data);
          this.searchEmpresa = response.data.razonsocial;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    changeEmpresa() {
      this.$emit("empresa-changed", this.empresa);
    },
    getEmpresaText(item) {
      return `${item.razonsocial})`;
    },
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;
      // Simulated ajax query
      //getPersonas(texto = null, tipo = null)
      VisitasService.getEmpresas(v, 1)
        .then((response) => {
          this.empresas = response.data.results;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });

      this.loading = false;
      //console.log("empresas: " + this.empresas[0]);
    },
  },
  watch: {
    searchEmpresa(val) {
      val && val !== this.empresa && this.querySelections(val);
    },
  },
};
</script>
