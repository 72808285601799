<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Ingreso</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <div v-if="!this.persona">
      <v-divider></v-divider>
      <v-card>
        <v-card-text>
          <DniIngresoInput
            @documento-changed="checkPersona"
            :documento="documento"
          >
          </DniIngresoInput>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="this.persona">
      <PersonaCard :persona="this.persona" :ingreso="true"></PersonaCard>
    </div>
    <br />
    <div v-if="this.persona">
      <IngresoForm
        :persona="this.persona"
        @ingreso-success="onSuccess"
        @ingreso-reset="OnReset"
      ></IngresoForm>
    </div>
  </v-container>
</template>

<script>
import DniIngresoInput from "../components/DniIngresoInput";
import IngresoForm from "../components/IngresoForm";
import PersonaCard from "../components/PersonaCard.vue";

export default {
  name: "Ingreso",

  components: {
    DniIngresoInput,
    IngresoForm,
    PersonaCard,
  },
  data() {
    return {
      persona: null,
    };
  },
  props: ["documento"],

  methods: {
    checkPersona(per) {
      this.persona = per;
    },

    OnReset() {
      this.persona = null;
      this.documento = null;
    },

    onSuccess() {
      this.documento = null;
      this.persona = null;
      //this.$router.go();
      //this.$router.push({ name: "Ingreso" });
    },

    cancelar() {},
  },
};
</script>
