<template>
  <div>
    <v-alert dense v-if="error" type="error"
      >El documento ingresado ya registra un ingreso el día de la fecha sin
      registrar egreso.
      <v-btn
        text
        :to="{ name: 'Accesos', params: { documento: this.documentoNumero } }"
        >Comprobar accesos.</v-btn
      ></v-alert
    >
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-form ref="form" v-model="formValid" @submit.prevent>
          <v-text-field
            ref="dniInput"
            prepend-icon="mdi-account"
            v-model="documentoNumero"
            label="Número de Documento"
            :counter="11"
            :rules="documentoRules"
            required
            :disabled="btndisable"
            @change="searchDocumento"
          >
            <template slot="append-outer">
              <v-icon right @click="scanControl">mdi-barcode</v-icon>
              <v-icon
                right
                @click="
                  scancam = !scancam;
                  scan = false;
                "
                >mdi-webcam</v-icon
              >
            </template>
          </v-text-field>
          <v-text-field
            v-if="scan"
            v-model="scanCode"
            ref="inputScan"
            prepend-icon="mdi-barcode-scan"
            placeholder="Escanee el DNI con el lector"
            autofocus
            clearable
            required
            :rules="scanRules"
            @change="scanControlDecode"
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="4">
        <div class="barcode_scan" v-if="scancam">
          <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row v-if="persona">
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">Nombres</th>
              <td>{{ this.persona.nombres }}</td>
            </tr>
            <tr>
              <th class="text-left">Apellidos</th>
              <td>{{ this.persona.apellidos }}</td>
            </tr>
          </thead>
        </v-simple-table>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";
import dniDecode from "../helpers/personas.js";
import StreamBarcodeReader from "@/components/StreamBarcodeReader.vue";

export default {
  name: "DniIngresoInput",
  components: {
    StreamBarcodeReader,
  },

  data() {
    return {
      tipos: [],
      search: "",
      telefonotipo: "",
      documentoNumero: "",
      lectura: "",
      scancam: false,
      scan: false,
      scanCode: "",
      btndisable: false,
      formValid: false,
      datosDni: null,
      documentoRules: [
        (v) => !!v || "Debe ingresar un número de documento",
        (v) =>
          (v && v.length <= 11) ||
          "El documento no puede tener mas de 11 caracteres.",
        (v) =>
          (v && v.length > 6) ||
          "El documento no puede tener menos de 7 caracteres.",
        (v) => (v && !isNaN(v)) || "Debe ingresar un número.",
      ],
      scanRules: [
        (v) => !!v || "Debe ingresar una lectura",
        (v) =>
          (v && v.length > 40) ||
          "La lectura ingresada tiene un formato incorrecto",
        (v) =>
          (v && v.split('"').length - 1 > 6) ||
          "La lectura ingresada tiene un formato incorrecto",
      ],
      persona: null,
      error: false,
    };
  },
  props: {
    documento: {
      type: String,
      required: false,
    },
  },
  methods: {
    scanControl() {
      this.scan = !this.scan;
      this.scancam = false;
      if (this.scan) {
        this.$refs.dniInput.reset();
        this.formValid = false;
      }
    },

    scanControlDecode(datosLector) {
      if (this.$refs["inputScan"].valid) {
        this.datosDni = dniDecode(datosLector, '"');
        this.scan = false;
        this.searchDocumento(this.datosDni.documento, this.datosDni);
      }
    },

    onDecode(result) {
      this.datosDni = dniDecode(result);
      this.scancam = false;
      this.searchDocumento(this.datosDni.documento, this.datosDni);
    },

    searchDocumento(documento, datosDni = null) {
      if (!this.formValid && !datosDni) return;

      VisitasService.getPersonaByDocumentoIngreso(documento)
        .then((response) => {
          if (response.data.results.length) {
            this.persona = response.data.results[0];
          } else {
            if (datosDni) {
              this.$router.push({
                name: "PersonaAlta",
                query: this.datosDni,
              });
            } else {
              this.$router.push({
                name: "PersonaAlta",
                query: { documento: documento },
              });
            }
            return;
          }
          this.error = false;
          this.$emit("documento-changed", this.persona);
        })
        .catch((error) => {
          this.error = true;
          this.persona = null;
          this.btndisable = false;
        });
    },
  },
  created() {
    if (this.documento) {
      this.documentoNumero = this.documento;
      this.formValid = true;
      this.searchDocumento(this.documentoNumero);
    }

    // this.datosDni = dniDecode(
    //   "00111671111@APELLIDO@NOMBRE@M@28123456@A@10/11/1988@07/06/2015@702"
    // );
    // this.$router.push({
    //   name: "PersonaAlta",
    //   query: this.datosDni,
    // });
  },
};
</script>

<style>
.barcode_scan {
  max-width: 200px;
  max-height: 200px;
  z-index: -20;
}
</style>
