<template>
  <div>
    <v-autocomplete
      v-model="autoriza"
      :items="personas"
      :item-text="getPersonaText"
      item-value="id"
      :rules="autorizaRules"
      label="Autoriza"
      cache-items
      :loading="loading"
      :search-input.sync="searchAutoriza"
      no-data-text="No se encontraron resultados"
      @change="autorizaChange"
    >
      <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon
            :key="`icon-mdi-phone`"
            :color="autoriza ? 'success' : 'danger'"
            @click="autoriza ? emitirClick() : ''"
            v-text="autoriza ? 'mdi-phone' : 'mdi-phone-cancel'"
          ></v-icon>
        </v-slide-x-reverse-transition>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService";

export default {
  name: "PersonasAutorizaAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      searchAutoriza: null,
      autoriza: null,
      personas: [],
      loading: false,
      autorizaRules: [
        (v) => !!v || "Debe seleccionar una persona.",
        (v) =>
          v != this.persona_id ||
          "Debe ser una persona diferente a la que ingresa.",
      ],
    };
  },
  methods: {
    autorizaChange() {
      this.$emit("autoriza-changed", this.autoriza);
    },
    emitirClick() {
      this.$emit("autoriza-click", this.autoriza);
    },

    getPersonaText(item) {
      return `${item.nombres} ${item.apellidos} (${item.documento_nro})`;
    },
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;
      // Simulated ajax query
      //getPersonas(texto = null, tipo = null)
      VisitasService.getPersonas(v, 1)
        .then((response) => {
          this.personas = response.data.results;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });

      this.loading = false;

      // setTimeout(() => {
      //   this.items = this.states.filter((e) => {
      //     return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
      //   });
      // }, 500);
    },
  },
  watch: {
    searchAutoriza(val) {
      val && val !== this.visita && this.querySelections(val);
    },
  },
  props: {
    persona_id: {
      type: Number,
      required: true,
    },
  },
};
</script>
