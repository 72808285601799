<template>
  <div v-if="persona.tipo" class="mx-auto mt-5">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <div class="text-center">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon color="info" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <img
              v-if="this.foto"
              width="80%"
              :src="this.foto.foto"
              alt="persona foto"
            />
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <v-card>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ nombreCompleto }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.persona.get_tipo_display }}
          </v-card-subtitle>
        </v-col>
        <v-col class="text-right">
          <div class="text-no-wrap photo-container">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-avatar v-else size="80">
              <img
                v-if="foto"
                :src="this.foto.foto"
                alt="no img"
                class="photo-viewport"
                @click="dialog = true"
              />
              <img
                v-else
                src="../assets/no-photo.png"
                alt="no profile picture"
              />
            </v-avatar>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <p>
              <strong>Género: </strong>{{ this.persona.get_genero_display }}
            </p>
            <p>
              <strong>Documento: </strong
              >{{ this.persona.get_documento_tipo_display }} -
              {{ this.persona.documento_nro }}
            </p>
            <p>
              <strong>Unidad Funcional: </strong
              >{{ this.persona.unidad_funcional }}
            </p>
            <p v-if="this.persona.telefonos.length">
              <strong>Teléfonos: </strong>
            </p>
            <ul>
              <li v-for="telefono in this.persona.telefonos" :key="telefono.id">
                {{ telefono.get_tipo_display }}: {{ telefono.numero }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="!ingreso">
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :to="'/persona/' + this.persona.id + '/editar'"
            ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "PersonaCard",
  data() {
    return {
      showPassword: false,
      foto: null,
      loading: true,
      dialog: false,
    };
  },
  props: {
    persona: {
      type: Object,
      required: true,
    },
    ingreso: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.loadProfilePicture();
  },
  methods: {
    loadProfilePicture() {
      VisitasService.getPersonaFoto(this.persona.id)
        .then((response) => {
          this.foto = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  computed: {
    nombreCompleto: function () {
      return this.persona.nombres + " " + this.persona.apellidos;
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}

.person-detail-p {
  font-size: 1.5em;
}

.person-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>

<style>
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
