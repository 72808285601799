<template>
  <div>
    <br />
    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="490">
        <v-card>
          <v-card-title class="text-h5"
            >Autorizar Ingreso - Teléfonos
          </v-card-title>
          <v-card-text v-if="this.autoriza"
            >Contacte con {{ this.autoriza.nombres }}
            {{ this.autoriza.apellidos }} para autorizar la visita.
            <br />
            <br />
            <v-list-item
              v-for="telefono in this.autoriza.telefonos"
              :key="telefono.id"
            >
              <v-list-item-content>
                <v-list-item-title
                  >{{ telefono.get_tipo_display }}:
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  telefono.numero
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <br />
            ¿Continuar?
          </v-card-text>
          <v-card-text v-else
            >No se registraron teléfonos de contácto para la persona
            seleccionada.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn
              color="primary darken-1"
              text
              @click="
                dialog = false;
                autorizado = true;
              "
            >
              Autorizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-card>
      <v-card-title>Detalles Visita</v-card-title>

      <v-form v-model="formValidity" ref="form" @submit.prevent="onSubmit">
        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                prepend-icon="mdi-thermometer"
                v-model="temperatura"
                :counter="4"
                label="Temperatura"
                suffix="ºC"
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <EmpresasAutocomplete @empresa-changed="onEmpresaChanged">
              </EmpresasAutocomplete>
            </v-col>

            <v-col cols="12" md="3">
              <PersonasVisitaAutocomplete
                :persona_id="this.persona.id"
                @visitaA-changed="onVisitaChanged"
              >
              </PersonasVisitaAutocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <PersonasAutorizaAutocomplete
                :persona_id="this.persona.id"
                @autoriza-changed="onAutorizaChange"
                @autoriza-click="onAutorizaPhone()"
              >
              </PersonasAutorizaAutocomplete>
              <!-- <v-autocomplete
                v-model="autoriza_id"
                :items="personas_autoriza"
                :item-text="getPersonaText"
                item-value="id"
                label="Autoriza"
                :rules="autorizaRules"
                cache-items
                :loading="loading_autoriza"
                no-data-text="No se encontraron resultados"
                @change="onAutorizaChange"
              >
                <template v-slot:append-outer>
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-icon
                      :key="`icon-mdi-phone`"
                      :color="autoriza_id ? 'success' : 'danger'"
                      @click="autoriza_id ? onAutorizaPhone() : ''"
                      v-text="autoriza_id ? 'mdi-phone' : 'mdi-phone-cancel'"
                    ></v-icon>
                  </v-slide-x-reverse-transition>
                </template>
              </v-autocomplete> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn color="success" @click="addElementoForm">
                <v-icon left dark>mdi-plus</v-icon>Agregar Elementos</v-btn
              >
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col>
              <ElementoInputs
                v-for="(elemento, index) in elementos"
                :key="index"
                :elemento="elemento"
                @elementoForm-deleted="removeElementoForm"
              >
                {{ index }}
              </ElementoInputs>
            </v-col>
          </v-row>
          <v-divider> </v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!checkValidity" color="info" type="submit"
              ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
            >
            <v-btn color="error" @click="$emit('ingreso-reset')"
              ><v-icon left dark> mdi-refresh </v-icon>Reiniciar</v-btn
            >
            <!-- <v-btn color="info" to="/personas">Cancelar</v-btn> -->
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import VisitasService from "../services/VisitasService";
import ElementoInputs from "./ElementoInputs.vue";
import PersonasVisitaAutocomplete from "./Ingreso/PersonasVisitaAutocomplete.vue";
import PersonasAutorizaAutocomplete from "./Ingreso/PersonasAutorizaAutocomplete.vue";
import EmpresasAutocomplete from "./Ingreso/EmpresasAutocomplete.vue";

export default {
  name: "IngresoForm",
  components: {
    ElementoInputs,
    PersonasVisitaAutocomplete,
    PersonasAutorizaAutocomplete,
    EmpresasAutocomplete,
  },

  data() {
    return {
      elementos: [],
      formValidity: false,
      dialog: false,
      personatipo: null,
      temperatura: null,
      visita_id: null,
      autoriza_id: null,
      empresa_id: null,
      autoriza: null,
      autoriza_telefonos: null,
      autorizado: false,
      successMsg: null,
      //personas: [],

      nombresRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
      documentoRules: [
        (v) => !!v || "Debe ingresar un número de documento",
        (v) =>
          v.length <= 11 ||
          "La longuitud del campo documento número debe ser menor a 12 caracteres.",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
    };
  },
  created() {
    if (this.persona !== null)
      // {} Si persona no es null, cargar los datos que correspondan
      VisitasService.getPersonas()
        .then((response) => {
          this.personas = response.data.results;
        })
        .catch((error) => console.log(error));

    //this.elementos.push(this.createElemento());
  },
  computed: {
    checkValidity() {
      if (this.formValidity && this.autorizado) return true;
      else return false;
    },
  },
  methods: {
    onSubmit() {
      let accesoNuevo = {
        persona: this.persona.id,
        ingreso: null,
        egreso: null,
        temperatura: this.temperatura,
        visita: this.visita_id,
        autoriza: this.autoriza_id,
        elementos: this.elementos,
        empresa: this.empresa_id,
      };

      VisitasService.postIngreso(accesoNuevo)
        .then((response) => {
          this.successMsg = "Ingreso registrado correctamente!";
          this.$emit("ingreso-success");
        })
        .catch((error) => console.log(error));
    },
    onAutorizaPhone() {
      this.autoriza = this.personas.find((x) => x.id === this.autoriza_id);
      this.dialog = true;

      // VisitasService.getPersonaTelefonos(this.autoriza)
      //   .then((response) => {
      //     this.autoriza_telefonos = response.data;
      //     console.log(this.autoriza_telefonos);
      //     if (!this.autoriza_telefonos.length) this.autoriza_telefonos = null;
      //     this.dialog = true;
      //   })
      //   .catch((error) => {
      //     console.log(error);

      //     this.dialog = true;
      //   });
    },
    getPersonaText(item) {
      return `${item.nombres} ${item.apellidos} (${item.documento_nro})`;
    },
    validate() {
      this.$refs.form.validate();
    },
    addElementoForm() {
      this.elementos.push(this.createElemento());
    },
    removeElementoForm(elemento) {
      console.log(elemento);
      if (this.elementos.length > 0)
        this.elementos.splice(this.elementos.indexOf(elemento), 1);
    },

    onEmpresaChanged(empresa_id) {
      this.empresa_id = empresa_id;
    },

    onVisitaChanged(visita_id) {
      this.visita_id = visita_id;
    },
    onAutorizaChange(autoriza_id) {
      this.autorizado = false;
      this.autoriza_id = autoriza_id;
    },
    createElemento() {
      elemento = null;
      let elemento = {
        id: null,
        acceso: null,
        descripcion: "",
        numero_serie: "",
        controlar: false,
      };
      return elemento;
    },
  },
  props: {
    persona: {
      type: Object,
      required: true,
    },
  },
};
</script>
