<template>
  <div>
    <v-autocomplete
      v-model="visita"
      :items="personas"
      :item-text="getPersonaText"
      item-value="id"
      :rules="autorizaRules"
      label="Visita a"
      cache-items
      :loading="loading"
      :search-input.sync="searchVisita"
      no-data-text="No se encontraron resultados"
      @change="visitaAChange"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService";

export default {
  name: "PersonasVisitaAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      searchVisita: null,
      visita: null,
      personas: [],
      loading: false,
      autorizaRules: [
        (v) => !!v || "Debe seleccionar una persona",
        (v) =>
          v != this.persona_id ||
          "Debe ser una persona diferente a la que ingresa.",
      ],
    };
  },
  methods: {
    visitaAChange() {
      this.$emit("visitaA-changed", this.visita);
    },
    getPersonaText(item) {
      return `${item.nombres} ${item.apellidos} (${item.documento_nro})`;
    },
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;
      VisitasService.getPersonas(v, 1)
        .then((response) => {
          this.personas = response.data.results;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });

      this.loading = false;
    },
  },
  watch: {
    searchVisita(val) {
      val && val !== this.visita && this.querySelections(val);
    },
  },
  props: {
    persona_id: {
      type: Number,
      required: true,
    },
  },
};
</script>
